




























































































































































































































import FroalaEditor from 'froala-editor/js/froala_editor.min'
import { azBlobService } from '@/shared/services'
import { mapGetters } from 'vuex'

export default {
  name: 'ImageManager',

  props: {
    source: {
      type: String,
      required: true
    },
    destination: {
      type: String,
      default: ''
      // required: true
    }
  },

  data: () => ({
    isVisible: false,
    items: null,
    editor: null,
    loadingImage: false,
    blobFolders: []
  }),

  computed: {
    ...mapGetters(['simulatedCustomerCrmId', 'isLoading']),
    folderPath () {
      return `${this.source}${this.blobFolders.length > 0 ? this.blobFolders.join('/') + '/' : ''}`
    }
  },

  watch: {
    blobFolders (newValue, oldValue) {
      this.loadFolder()
    }
  },

  created () {
    const context: any = this

    Object.assign(FroalaEditor.DEFAULTS, {
      imageInsertButtons: ['imageUpload', 'imageByURL', 'vueImageManager']
    })

    FroalaEditor.DefineIcon('vueImageManager', {
      NAME: 'folder',
      SVG_KEY: 'imageManager'
    })

    FroalaEditor.RegisterCommand('vueImageManager', {
      title: 'Browse Content',
      icon: 'vueImageManager',
      undo: false,
      focus: false,
      modal: true,
      callback: function () {
        context.editor = this
        context.init()
      }
    })
  },

  methods: {
    async init () {
      await this.loadFolder()
    },
    async loadFolder () {
      const context = this
      this.items = null
      this.loadingImage = false
      setTimeout(() => { context.isVisible = true }, 10)
      azBlobService.getImages(this.simulatedCustomerCrmId(), this.folderPath).then(result => {
        context.items = result.map(x => { return { ...x, showImageSelected: false } })
          .sort((a, b) => a.isBlob && !b.isBlob ? 1 : (!a.isBlob && b.isBlob ? -1 : (a.name >= b.name ? 1 : -1)))
      })
    },
    closeImage (item) {
      item.showImageSelected = false
    },
    async selected (item, img) {
      if (this.editor != null) {
        this.loadingImage = true
        let response = await azBlobService.copyImage(this.simulatedCustomerCrmId(), img, this.destination)
        setTimeout(this.editor.image.insert(response.link, true, {}, this.editor.image.get()), 10)
        this.loadingImage = false
        this.closeImage(item)
      }
      this.cancel()
    },
    cancel () {
      this.isVisible = false
    },
    rootFolder () {
      this.blobFolders = []
    },
    updateFolderPath (itemPath) {
      this.blobFolders = itemPath.split('/')
    },
    goToNextFolder (name) {
      this.blobFolders.push(name)
    }
  }
}
