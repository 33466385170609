var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: "",
        "hide-overlay": "",
        transition: "dialog-bottom-transition"
      },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.cancel.apply(null, arguments)
        }
      },
      model: {
        value: _vm.isVisible,
        callback: function($$v) {
          _vm.isVisible = $$v
        },
        expression: "isVisible"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", color: "primary" } },
            [
              _c("v-toolbar-title", [_vm._v("Images")]),
              _c("v-spacer"),
              _c(
                "v-toolbar-items",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function($event) {
                          return _vm.cancel()
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card",
            [
              _c(
                "v-row",
                { staticClass: "ma-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "mt-3 px-8", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-sheet",
                        { attrs: { "max-width": "75%" } },
                        [
                          _c(
                            "v-slide-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "prev",
                                  fn: function() {
                                    return [
                                      _c("v-icon", { ref: "pathPrev" }, [
                                        _vm._v(
                                          "\n                  mdi-chevron-left\n                "
                                        )
                                      ])
                                    ]
                                  },
                                  proxy: true
                                }
                              ])
                            },
                            [
                              _c("v-slide-item", {
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var active = ref.active
                                      var toggle = ref.toggle
                                      return [
                                        _c(
                                          "span",
                                          { staticClass: "pa-1" },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  small: "",
                                                  "input-value":
                                                    _vm.blobFolders.length ===
                                                    0,
                                                  "active-class": "secondary"
                                                },
                                                on: { click: _vm.rootFolder }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    Root\n                    "
                                                ),
                                                _vm.blobFolders.length === 0
                                                  ? _c(
                                                      "v-icon",
                                                      { attrs: { right: "" } },
                                                      [
                                                        _vm._v(
                                                          "mdi-folder-open"
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            ),
                                            _vm.blobFolders.length > 0
                                              ? _c(
                                                  "v-icon",
                                                  { staticClass: "pl-2" },
                                                  [_vm._v("mdi-chevron-right")]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _vm._l(_vm.blobFolders, function(item, i) {
                                return _c("v-slide-item", {
                                  key: i,
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var active = ref.active
                                          var toggle = ref.toggle
                                          return [
                                            _c(
                                              "span",
                                              { staticClass: "pa-1" },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    ref: "pathLast",
                                                    refInFor: true,
                                                    attrs: {
                                                      small: "",
                                                      "input-value":
                                                        i ===
                                                        _vm.blobFolders.length -
                                                          1,
                                                      "active-class":
                                                        "secondary"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        _vm.updateFolderPath(
                                                          _vm.blobFolders
                                                            .slice(0, i + 1)
                                                            .join("/")
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(item) +
                                                        "\n                    "
                                                    ),
                                                    i ===
                                                    _vm.blobFolders.length - 1
                                                      ? _c(
                                                          "v-icon",
                                                          {
                                                            attrs: { right: "" }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-folder-open"
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                ),
                                                i !== _vm.blobFolders.length - 1
                                                  ? _c(
                                                      "v-icon",
                                                      { staticClass: "pl-2" },
                                                      [
                                                        _vm._v(
                                                          "mdi-chevron-right"
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          _vm.items === null
                            ? _c(
                                "v-row",
                                _vm._l(36, function(index) {
                                  return _c(
                                    "v-col",
                                    {
                                      key: index,
                                      attrs: {
                                        cols: "4",
                                        sm: "3",
                                        md: "2",
                                        lg: "1"
                                      }
                                    },
                                    [
                                      _c("v-skeleton-loader", {
                                        attrs: {
                                          type: "image",
                                          "max-height": "150"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _c(
                                "v-row",
                                { attrs: { justify: "start", align: "start" } },
                                _vm._l(_vm.items, function(item) {
                                  return _c(
                                    "v-col",
                                    {
                                      key: item.url,
                                      staticClass:
                                        "d-flex align-start justify-center",
                                      attrs: {
                                        cols: "4",
                                        sm: "3",
                                        md: "2",
                                        lg: "1"
                                      }
                                    },
                                    [
                                      item.isBlob
                                        ? _c(
                                            "v-dialog",
                                            {
                                              attrs: { persistent: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-card",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                flat: "",
                                                                tile: ""
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c("p", [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    item.name
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]),
                                                            _c(
                                                              "v-img",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "grey lighten-2 pointer",
                                                                  attrs: {
                                                                    src:
                                                                      item.url,
                                                                    contain: "",
                                                                    "max-height":
                                                                      "200",
                                                                    "max-width":
                                                                      "200",
                                                                    "aspect-ratio":
                                                                      "1"
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "placeholder",
                                                                        fn: function() {
                                                                          return [
                                                                            _c(
                                                                              "v-skeleton-loader",
                                                                              {
                                                                                attrs: {
                                                                                  type:
                                                                                    "image"
                                                                                }
                                                                              }
                                                                            )
                                                                          ]
                                                                        },
                                                                        proxy: true
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                },
                                                                on
                                                              )
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: item.showImageSelected,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "showImageSelected",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.showImageSelected"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-card",
                                                [
                                                  _c("v-card-title", [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(item.name) +
                                                        " \n                      "
                                                    ),
                                                    item.metadata &&
                                                    item.metadata.Height &&
                                                    item.metadata.Width
                                                      ? _c("div", [
                                                          _vm._v(
                                                            "\n                        - (" +
                                                              _vm._s(
                                                                item.metadata
                                                                  .Width
                                                              ) +
                                                              "x" +
                                                              _vm._s(
                                                                item.metadata
                                                                  .Height
                                                              ) +
                                                              ")\n                      "
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]),
                                                  _c(
                                                    "v-card-text",
                                                    { staticClass: "pt-4" },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          attrs: {
                                                            justify: "center"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "d-flex align-start justify-center",
                                                              attrs: {
                                                                lg: "6",
                                                                md: "8",
                                                                sm: "10",
                                                                cols: "12"
                                                              }
                                                            },
                                                            [
                                                              _c("v-img", {
                                                                staticClass:
                                                                  "grey lighten-2",
                                                                attrs: {
                                                                  src: item.url,
                                                                  contain: "",
                                                                  height:
                                                                    item.metadata &&
                                                                    item
                                                                      .metadata
                                                                      .Height
                                                                      ? item
                                                                          .metadata
                                                                          .Height
                                                                      : "",
                                                                  width:
                                                                    item.metadata &&
                                                                    item
                                                                      .metadata
                                                                      .Width
                                                                      ? item
                                                                          .metadata
                                                                          .Width
                                                                      : ""
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-card-actions",
                                                    [
                                                      _c("v-spacer"),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "tertiary",
                                                            disabled:
                                                              _vm.loadingImage ||
                                                              _vm.isLoading()
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.closeImage(
                                                                item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        Cancel\n                      "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "success",
                                                            loading:
                                                              _vm.loadingImage ||
                                                              _vm.isLoading()
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.selected(
                                                                item,
                                                                item.url
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        Select\n                      "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _c(
                                            "v-card",
                                            {
                                              attrs: { flat: "", tile: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.goToNextFolder(
                                                    item.name
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("p", [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(item.name) +
                                                    "\n                  "
                                                )
                                              ]),
                                              _c(
                                                "v-icon",
                                                { attrs: { "x-large": "" } },
                                                [
                                                  _vm._v(
                                                    "\n                    mdi-folder\n                  "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }